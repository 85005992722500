@include product-overview {
	&__content {
		display: flex;
		flex-flow: row wrap;
		align-items: stretch;
		padding: $block-spacing / 2;
	}

	&__loader {
		font-size: 14px;
		color: $black;
		opacity: 1;

		i {
			font-size: 30px;
			margin-right: $block-spacing * 2;
		}

		&--active {
			margin-bottom: 67px;

			@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
				margin: $block-spacing * 3 0;
			}
		}
	}
}
