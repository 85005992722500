@mixin usp {
	@at-root .usp {
		width: 100%;
		margin: 0;
		flex: 1;

		&__item {
			color: $font-color;
			position: relative;
			display: inline-block;
			text-transform: uppercase;
			vertical-align: middle;
			font-size: 12px;
			padding: $block-spacing;

			strong {
				font-weight: 700;
			}
		}

		&__icon {
			font-size: 16px;
			color: $brand-color-2;
			margin: 0 $block-spacing / 4 0 0;

			&--star {
				color: $brand-color;
			}
		}

		&--product {
			.usp__item {
				font-size: 16px;
				display: block;
				padding: $block-spacing;
				padding-left: $block-spacing * 4;
				text-transform: capitalize;
				text-align: left;

				i {
					position: absolute;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
					margin-right: $block-spacing * 1.5;
					font-size: 20px;
				}
			}
		}

		&--header {
			justify-content: flex-start;
			display: flex;
			flex-flow: row nowrap;
			align-items: center;

			.usp__item {
				color: $white;
				font-size: 12px;
				letter-spacing: 1.8px;
				font-weight: 700;
				padding: 10px $block-spacing;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.usp__icon {
				display: none;
			}
		}

		&--mobile {
			display: none;
		}

		@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
			&__item {
				width: 100%;
				text-align: center;
				padding: 0;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			&--desktop {
				display: none;
			}

			&--mobile {
				display: block;
			}
		}

		@content;
	}
}
