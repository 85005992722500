.payment-icons {
	@extend %flex-row;

	align-items: center;
	justify-content: space-between;
	flex: 1;
	gap: $block-spacing;
	filter: grayscale(1);
	padding: $block-spacing;

	&__icon {
		max-height: 20px;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		justify-content: center;
	}
}
