.mobile-filters {
	&__toggle {
		@extend %flex-row;

		justify-content: space-between;
		gap: $block-spacing;
		align-items: center;
		background-color: $white;
		height: 44px;
		border: 1px solid $border-color-2;
		padding: 0 $block-spacing * 2;
		cursor: pointer;
		width: 169px;
		max-width: 100%;
	}

	&__icon {
		font-size: 16px;
		color: $black;
	}

	&__title {
		text-transform: uppercase;
		color: $black;
		font-size: 12px;
	}

	&__count {
		@extend %flex-row;

		align-items: center;
		justify-content: center;
		color: $white;
		height: 21px;
		border-radius: 21px;
		width: 21px;
		font-size: 10px;
		background-color: $background-color-2;
		font-weight: bold;
	}

	&__overlay {
		background-color: rgba($black, 0.4);
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 9;

		&--hidden {
			display: none;
		}
	}

	&__dropdown {
		background-color: $white;
		padding: 0;
		position: fixed;
		bottom: 0;
		right: 0;
		top: 0;
		z-index: 9;
		width: 100%;
		max-width: 390px;
		color: $black;
		display: flex;
		flex-direction: column;
		transform: translateX(0);
		transition: transform 200ms ease-in-out;

		&--hidden {
			transform: translateX(100%);
		}
	}

	&__top {
		@extend %flex-row;

		align-items: center;
		justify-content: space-between;

		&::after {
			content: ' ';
			width: calc(100% - #{$block-spacing * 8});
			height: 1px;
			opacity: 0.1;
			margin: 0 $block-spacing * 4;
			background-color: $border-color-2;
		}
	}

	&__heading {
		font-size: 24px;
		font-weight: bold;
		padding: $block-spacing * 4;
	}

	&__close {
		font-size: 24px;
		cursor: pointer;
		padding: $block-spacing * 4;
	}

	&__back {
		@extend %flex-row;

		align-items: center;
		font-size: 14px;
		text-transform: uppercase;
		cursor: pointer;

		i {
			font-size: 30px;
			margin-right: $block-spacing;
		}
	}

	&__mid {
		flex: 1;
		overflow-y: auto;
		padding: 0 $block-spacing * 4;

		&--hidden {
			display: none;
		}
	}

	&__bot {
		padding: 0 $block-spacing * 4 0;
		display: flex;
		flex-flow: column wrap;

		&::before {
			content: ' ';
			width: 100%;
			height: 1px;
			opacity: 0.1;
			margin: 0 0 $block-spacing * 4;
			background-color: $border-color-2;
		}
	}

	&__border {
		margin-top: $block-spacing * 3;
		width: 100%;
		height: 1px;
		opacity: 0.1;
		background-color: $border-color-2;
	}

	&__sorting {
		@extend %flex-row;

		align-items: center;
		justify-content: flex-start;
		flex-wrap: nowrap;
		overflow-x: auto;
		margin-bottom: $block-spacing * 3;
	}

	&__sorting-title {
		font-size: 14px;
		font-weight: bold;
		margin-bottom: $block-spacing * 2;
		letter-spacing: 1px;
	}

	&__container {
		@extend %flex-row;

		justify-content: space-between;
		align-items: center;
		padding: $block-spacing 0;

		&--flex-start {
			justify-content: flex-start;
		}
	}

	&__item {
		@extend %flex-row;

		min-height: 86px;
		cursor: pointer;
		align-items: center;
		justify-content: space-between;
		flex-wrap: nowrap;
		padding: $block-spacing * 3 0;
		border-bottom: 1px solid $border-color;

		i {
			font-size: 18px;
		}
	}

	&__sub-item {
		font-size: 18px;
		font-family: $secondary-font-family;
		border-bottom: 1px solid $border-color;
		padding: $block-spacing * 4 0;
	}

	&__name {
		font-weight: bold;
		font-size: 14px;
		padding: $block-spacing * 3 0;
	}

	&__selected {
		color: $font-color;
		font-size: 14px;
		margin-top: $block-spacing / 2;
	}

	&__sizes {
		display: flex;
		flex-flow: row wrap;
		gap: $block-spacing;
		align-items: center;
	}

	&__size {
		&-square {
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			justify-content: center;
			min-width: 47px;
			height: 47px;
			border: 1px solid $black;
			cursor: pointer;
			padding: $block-spacing;
		}

		&-value {
			font-size: 14px;
			font-weight: 700;
		}

		&-input {
			display: none;

			&:checked + .mobile-filters__size-square {
				background-color: $black;
				color: $white;
			}
		}
	}

	&__colors {
		width: 100%;
		display: grid;
		padding-bottom: $block-spacing;
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}

	&__color {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		&-circle {
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			justify-content: center;
			width: 47px;
			height: 47px;
			border: 1px solid transparent;
			border-radius: 47px;
		}

		&-code {
			width: 31px;
			height: 31px;
			border-radius: 31px;
			border: 1px solid $border-color;
		}

		&-name {
			display: block;
			font-size: 12px;
			text-align: center;
			width: 100%;
			padding: $block-spacing 0;
		}

		&-input {
			display: none;

			&:checked + .mobile-filters__color-circle {
				border-color: $black;
			}
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
		width: 100%;

		&__toggle {
			width: 100%;
		}
	}

	@include responsive(('mobile')) {
		&__dropdown {
			max-width: 100%;
		}
	}
}
