@mixin message {
	@at-root .message {
		width: 100%;
		position: relative;
		display: none;
		line-height: 1.5em;
		padding: $block-spacing;
		background-color: $white;

		&--error {
			color: $error-color;
			border: 1px solid $error-color;

			&::before {
				background-color: rgba($error-color, 0.2);
			}
		}

		&--success {
			color: $success-color;
			border: 1px solid $success-color;

			&::before {
				background-color: rgba($success-color, 0.2);
			}
		}

		&--visible {
			display: block;
		}

		&--fixed {
			position: fixed;
			top: 101px;
			left: 0;
			right: 0;
			z-index: 9;

			&::before {
				z-index: -1;
			}

			@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
				top: 99px;
			}
		}

		&::before {
			content: ' ';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
		}

		a {
			text-decoration: underline;
		}

		@content;
	}
}
