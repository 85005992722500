@mixin background() {
	@at-root .background {
		background-color: $background-color;
		width: 100%;

		&--white {
			background-color: $white;
		}

		&--product-cs {
			padding: $block-spacing * 4;

			@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
				padding: $block-spacing * 4 8.3333%;
			}
		}

		&--faq {
			padding: $block-spacing * 4;
		}

		&--product-usp {
			margin-top: $block-spacing * 2;
			padding: $block-spacing * 4 $block-spacing * 4 $block-spacing * 2 $block-spacing * 4;

			@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
				padding-left: 8.3333%;
				padding-right: 8.3333%;
			}
		}

		&--product-description {
			margin-top: $block-spacing * 2;
			padding: $block-spacing * 4;

			@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
				padding-left: 8.3333%;
				padding-right: 8.3333%;
			}
		}

		&--brand {
			min-height: 500px;
			display: flex;
			align-items: center;

			@include responsive(('desktop')) {
				min-height: 447px;
			}

			@include responsive(('tablet-landscape')) {
				min-height: 372px;
			}

			@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
				min-height: unset;
			}
		}

		&--look {
			background: linear-gradient(to right, transparent 0%, transparent 30%, $white 30%, $white 100%);

			@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
				background: $white;
			}
		}

		&--vertical-spacing {
			margin-top: $vertical-spacing;
			margin-bottom: $vertical-spacing;
		}

		&--vertical-spacing-top {
			margin-top: $vertical-spacing;
		}

		&--vertical-spacing-bottom {
			margin-bottom: $vertical-spacing;
		}

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			&--vertical-spacing {
				margin-top: $vertical-spacing-mobile;
				margin-bottom: $vertical-spacing-mobile;
			}

			&--vertical-spacing-top {
				margin-top: $vertical-spacing-mobile;
			}

			&--vertical-spacing-bottom {
				margin-bottom: $vertical-spacing-mobile;
			}
		}

		@content;
	}
}
