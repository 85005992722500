@include product-images {
	@extend %flex-row;

	position: relative;

	&__image {
		width: calc(33.33% - #{$block-spacing / 1.5});
		display: block;

		&:nth-child(-n + 4) {
			width: calc(50% - #{$block-spacing / 2});
		}

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			width: 100%;
		}
	}

	&__bullets {
		position: absolute;
		bottom: $block-spacing * 4;
		left: $block-spacing;
		right: $block-spacing;
		z-index: 1;
		padding: 0;
		display: flex;
		flex-flow: row wrap;
		gap: $block-spacing;
		align-items: center;
		justify-content: center;
	}

	&__bullet {
		padding: 0;
		border: 0;
		margin: 0;
		cursor: pointer;
		width: 24px;
		height: 24px;
		background: transparent;
		position: relative;

		&::after {
			position: absolute;
			top: 11px;
			left: 0;
			right: 0;
			height: 2px;
			width: 100%;
			display: block;
			content: ' ';
			background-color: $white;
		}
	}

	&__container {
		@extend %flex-row;

		align-items: flex-start;
		justify-content: flex-start;
		flex: 1;
		gap: $block-spacing;

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			display: none;
		}
	}

	&__carousel {
		display: none;

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			display: block;
		}
	}
}
