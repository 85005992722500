@include guide {
	font-size: 12px;
	color: $font-color-2;
	letter-spacing: 1.8px;
	justify-content: space-between;
	padding: $block-spacing * 4 $block-spacing $block-spacing * 2;
	text-transform: uppercase;

	&__container {
		@extend %flex-row;

		align-items: center;
		gap: $block-spacing;
	}

	&__item {
		@extend %flex-row;

		align-items: center;
		padding: 0;

		&:hover {
			text-decoration: none;
			color: $highlight-color;
		}
	}

	&--detail {
		padding-bottom: $block-spacing * 4;
	}
}
