@mixin pagination {
	@at-root .pagination {
		@extend %flex-row;

		align-items: center;
		justify-content: center;
		padding: $block-spacing calc(#{$block-spacing} / 2);

		&__page {
			@extend %paragraph;
			@extend %flex-row;

			transition: all 0.3s ease-in-out 0s;
			align-items: center;
			justify-content: center;
			margin: calc(#{$block-spacing} / 2);
			color: $black;
			background-color: $background-color;
			height: 40px;
			min-width: 40px;

			&:hover {
				background-color: $hover-color;
				color: $white;
			}

			&--active {
				background-color: $active-color;
				color: $white;
			}

			&--prev,
			&--next {
				@extend %heading6;
			}
		}

		&__container {
			display: flex;
			flex-flow: column wrap;
			align-items: center;
			justify-content: center;
			gap: $block-spacing * 3;
			width: 100%;
		}

		&__title {
			color: $font-color;
			font-size: 16px;
			font-weight: 700;
			letter-spacing: 2.4px;
			line-height: normal;
			text-align: center;
			text-transform: uppercase;
		}

		&__progress {
			width: 456px;
			max-width: 100%;
			height: 8px;
			border-radius: 8px;
			background-color: $background-color;
			position: relative;

			span {
				background-color: $black;
				height: 100%;
				min-width: 1%;
				max-width: 100%;
				display: block;
				border-radius: 8px;
				transition: width 0.3s ease-in-out 0s;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
			}

			@include responsive(('mobile')) {
				width: 100%;
			}
		}

		&__link {
			color: $font-color;
			font-size: 16px;
			font-weight: 700;
			letter-spacing: 2.4px;
			text-transform: uppercase;
			padding-bottom: $block-spacing;
			border-bottom: 1px solid $font-color;
			text-align: center;
		}

		@content;
	}
}
