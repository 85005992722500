@include product {
	border: 0;
	padding: $block-spacing / 2;
	font-family: $primary-font-family;
	display: flex;
	flex-flow: column wrap;

	&__name {
		color: $font-color-2;
		text-transform: uppercase;
		letter-spacing: 1.8px;
		font-size: 12px;
		font-weight: 700;
		display: block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__summary {
		font-size: 12px;
		color: $font-color;
		letter-spacing: 1.8px;
		line-height: normal;
	}

	&__info {
		display: flex;
		flex-flow: column wrap;
		gap: $block-spacing;
		align-items: flex-start;
		justify-content: space-between;
		background-color: $white;
		padding: $block-spacing * 3 $block-spacing * 2;
		flex: 1 1 auto;
		max-width: 100%;
	}

	&__info-top {
		width: 100%;
		display: flex;
		flex-flow: column wrap;
		gap: $block-spacing;
	}

	&__info-container {
		display: flex;
		width: 100%;
		flex-flow: row wrap;
		align-items: center;
		justify-content: space-between;
		gap: $block-spacing;
	}

	&__price {
		font-size: 12px;
		font-weight: 700;
		letter-spacing: 1.8px;
		gap: $block-spacing;
		color: $font-color-2;

		&__advice {
			text-decoration: line-through;
			color: $font-color-2;
			font-weight: 400;
		}

		&__highlight {
			color: $highlight-color-2;
		}
	}

	&__colors {
		@extend %flex-row;

		align-items: center;
		gap: $block-spacing;
		padding-top: $block-spacing;
	}

	&__color-code {
		display: block;
		width: 12px;
		height: 12px;
		border: 1px solid $border-color;
		border-radius: 12px;
		margin: 0;
	}

	&__color {
		width: 12px;
		height: 12px;
		border-radius: 12px;
		border: 1px solid transparent;

		&--active {
			border: 1px solid transparent;
		}
	}

	&__heading {
		@extend %paragraph;

		font-weight: bold;
		color: $black;
		display: block;
		width: 100%;
	}

	&__sizes {
		@extend %flex-row;

		align-items: center;
		background-color: $white;
		position: absolute;
		bottom: 92px;
		min-height: 92px;
		max-height: 92px;
		left: 0;
		width: 100%;
		padding: $block-spacing * 1.5;
		opacity: 0;
		transition: opacity 0.3s ease-in-out 0s, bottom 0.3s ease-in-out 0s;
		overflow: hidden;
		white-space: nowrap;

		&::before {
			content: ' ';
			display: block;
			height: 1px;
			width: calc(100% - #{$block-spacing * 4});
			background-color: $border-color;
			position: absolute;
			top: 0;
			left: $block-spacing * 2;
		}
	}

	&__size {
		@extend %small;

		margin: 0 $block-spacing / 2 $block-spacing / 2 0;
	}

	&__tags {
		@extend %flex-row;

		position: absolute;
		gap: $block-spacing / 2;
		top: 0;
		left: 0;
		width: calc(100% - 64px);
	}

	&__tag {
		font-size: 14px;
		font-family: $font-family-3;
		letter-spacing: 2.1px;
		line-height: normal;
		padding: $block-spacing / 2;
		font-weight: 800;
		display: inline-block;
		position: unset;
		top: unset;
		left: unset;
		background-color: $black;
		color: $white;
		text-transform: uppercase;

		@include responsive(('mobile')) {
			font-size: 10px;
		}

		&--2 {
			background-color: $highlight-color-2;
		}

		&--eco-friendly {
			height: 150px;
			width: 100%;
			background-color: transparent;
			background-image: url('#{$image-path}/eco_friendly.png');
			background-size: contain;
			background-position: top left;
			background-repeat: no-repeat;

			@include responsive(('mobile')) {
				height: 100px;
			}
		}
	}

	&__image-placeholder {
		&::after {
			padding-bottom: 150%;
		}
	}

	&__image {
		transition: opacity 0.3s ease-in-out 0s;

		&--first {
			opacity: 1;
		}

		&--second {
			opacity: 0;
		}
	}

	.button {
		position: absolute;
		bottom: $block-spacing * 2;
		left: $block-spacing * 2;
		right: $block-spacing * 2;
		opacity: 0;
	}

	&:hover {
		.button {
			opacity: 1;
		}

		.product__image {
			&--first {
				opacity: 0;
			}

			&--second {
				opacity: 1;
			}
		}

		.product__sizes {
			opacity: 1;
			bottom: 0;
		}
	}

	&--overview {
		width: 25%;

		@include responsive(('tablet-landscape')) {
			width: 33.33%;
		}

		@include responsive(('mobile-landscape', 'tablet')) {
			width: 50%;
		}

		@include responsive(('mobile')) {
			width: 50%;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
		margin-bottom: $block-spacing * 3;
		padding-bottom: 0;

		&__info {
			padding: $block-spacing;
		}

		&__sizes {
			opacity: 1;
			position: unset;
			bottom: auto;
			left: auto;
			padding: $block-spacing;
			border-top: 1px solid $border-color;
			min-height: auto;
			max-height: unset;
		}
	}

	@include responsive(('mobile')) {
		&__info {
			padding: $block-spacing 0;
		}
	}
}
