@include footer {
	background-color: $white;
	align-items: center;
	justify-content: center;
	padding: 0;
	border: 0;
	margin: 0;

	&__title {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: flex-start;
		flex: 1;
		font-family: $secondary-font-family;
		color: $white;
		font-size: 48px;
		height: 100%;
		text-transform: uppercase;
	}

	&__top {
		width: 100%;
		padding-top: $block-spacing * 2.5;
		padding-bottom: $block-spacing * 2.5;
		background: linear-gradient(to right, $background-color-2 0%, $background-color-2 50%, $white 50%, $white 100%);
	}

	&__mid {
		border-top: 1px solid rgba($white, 0.2);
		padding-top: $block-spacing * 2.5;
		padding-bottom: $block-spacing * 2.5;
		width: 100%;
		background-color: $background-color;
	}

	&__bot {
		@extend %flex-row;

		align-items: center;
		background-color: $white;
		width: 100%;
		min-height: 80px;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		&__top {
			background: $white;
		}

		&__title {
			min-height: 170px;
			padding: $block-spacing;
			background-color: $background-color-2;
			justify-content: center;
		}
	}
}
