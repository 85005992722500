.categories {
	padding: $block-spacing * 4 $block-spacing * 3 $block-spacing * 3 $block-spacing * 3;
	display: flex;
	flex: 1;
	flex-flow: column wrap;
	width: 100%;

	&__container {
		gap: $block-spacing * 4;
		margin-top: $block-spacing * 3;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: center;
	}

	&__link {
		font-family: $primary-font-family;
		color: $font-color;
		font-weight: 400;
		font-size: 14px;
		letter-spacing: 2.1px;
		text-transform: uppercase;
		border-bottom: 1px solid $black;
		padding: $block-spacing 0;

		&--active {
			font-weight: 700;
		}

		&:hover {
			color: $highlight-color;
			border-color: $highlight-color;
		}
	}

	&__scroll {
		white-space: nowrap;
		overflow-y: auto;
		width: 100%;

		.categories__container {
			flex-wrap: nowrap;
		}
	}

	&--overview {
		padding: 0 $block-spacing $block-spacing * 3;

		.categories__container {
			margin: 0;
			justify-content: flex-start;
			gap: $block-spacing * 2;
		}

		.categories__link {
			font-size: 12px;
			letter-spacing: 1.8px;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
		padding: 0 $block-spacing $block-spacing;

		&--home {
			padding: $block-spacing;
		}
	}
}
