@mixin news-item() {
	@at-root .news-item {
		display: flex;
		flex-flow: row wrap;
		align-items: stretch;
		padding: $block-spacing;
		width: 100%;

		&__image {
			position: relative;
			width: 50%;
			min-height: 100%;
			height: auto;
			background-size: cover;
			background-position: center top;

			&::after {
				content: ' ';
				display: block;
				padding-bottom: 83%;
			}

			&--detail {
				img {
					width: 100%;
				}

				&::after {
					content: unset;
				}
			}
		}

		&__content {
			width: 50%;
			display: flex;
			flex-flow: column wrap;
			justify-content: center;
			padding: $vertical-spacing 8%;
			gap: $block-spacing * 2;
		}

		&__date {
			color: $font-color-2;
			font-size: 12px;
			font-weight: 400;
			line-height: normal;
		}

		&__category {
			color: $font-color-2;
			font-size: 14px;
			line-height: normal;
			font-weight: 300;
			letter-spacing: 2.1px;
			text-transform: uppercase;
		}

		&__title {
			color: $font-color;
			font-size: 24px;
			line-height: normal;
			font-weight: 700;
		}

		&__link {
			color: $font-color;
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			gap: $block-spacing * 2;

			i {
				font-size: 40px;
			}

			span {
				border-bottom: 1px solid $font-color;
				padding-bottom: $block-spacing;
				text-transform: uppercase;
				font-size: 12px;
				font-weight: 400;
				line-height: normal;
				letter-spacing: 1.8px;
			}
		}

		&:nth-child(even) {
			.news-item__image {
				order: 2;
			}
		}

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			&__image {
				min-height: unset;
				width: 100%;
				order: 1 !important;
			}

			&__content {
				width: 100%;
				order: 2 !important;
			}
		}

		@content;
	}
}
