@include sorting {
	justify-content: flex-end;

	&__content {
		font-size: 14px;
		font-weight: bold;
		color: $black;
		padding: $block-spacing $block-spacing * 2;
	}

	&__select {
		font-size: 12px;
		background-color: $white;
		border: 1px solid $border-color-2;
		font-weight: 400;
		text-transform: uppercase;
		color: $font-color;
		padding: $block-spacing * 1.5 $block-spacing * 3.5 $block-spacing * 1.5 $block-spacing * 2;
		background-position: center right $block-spacing * 1.5;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
		justify-content: flex-start;

		&__select {
			width: 100%;
		}
	}
}
