.faq {
	color: $black;
	line-height: 1.5em;

	&__question {
		font-size: 14px;
		color: $font-color;
		letter-spacing: 2.1px;
		text-decoration: underline;
		padding: $block-spacing $block-spacing * 2 $block-spacing * 2 0;
		cursor: pointer;
		font-weight: 300;
		border-bottom: 1px solid $border-color;
	}

	&__answer {
		font-size: 14px;
		padding: $block-spacing * 2 0;
		font-weight: 400;
		line-height: 24px;
		color: $font-color-2;
		border-bottom: 1px solid $border-color;

		b {
			font-weight: 900;
		}
	}

	.accordion__icon-open,
	.accordion__icon-close {
		transform: unset;
		top: $block-spacing;
		right: 0;
		font-size: 14px;
		color: $font-color;
	}

	&--article {
		border-top: 1px solid $border-color;

		.faq__question {
			padding: $block-spacing * 2 $block-spacing * 2 $block-spacing * 2 0;
		}

		.accordion__icon-open,
		.accordion__icon-close {
			top: $block-spacing * 2;
		}
	}
}
