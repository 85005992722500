// .iti is from the "intl-tel-input" dependency
.iti,
.phone-field {
	width: 100%;
	height: 56px;
}

.iti__selected-dial-code,
.phone-field {
	font-weight: 700;
	letter-spacing: 1.8px;
	text-transform: uppercase;
	font-family: $primary-font-family;
	color: $font-color;
	font-size: 12px;
}

.iti {
	.iti__selected-dial-code {
		// vertical align with input text
		font-size: 13px;
	}
}

.phone-field {
	width: 100%;
	height: 100%;
	border-width: 1px;
	border-radius: unset;
	outline: none;
	appearance: none;

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		appearance: none;
		margin: 0;
	}

	&:focus {
		border-width: 2px;
		border-color: $active-color;
	}
}
