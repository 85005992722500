.filters-container {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	gap: $block-spacing;

	.filters-container__col {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: center;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
		justify-content: space-between;

		.filters-container__col {
			width: calc(50% - #{$block-spacing / 2});
		}
	}
}
