@mixin shoppingcart-total {
	@at-root .shoppingcart-total {
		@extend %flex-row;

		padding: #{$block-spacing * 2} 0;

		&__content {
			@extend %paragraph;

			display: inline-block;
			width: 70%;
			text-align: right;

			&--success {
				color: $success-color;
			}

			&--bold {
				font-weight: bold;
			}

			&--value {
				width: 30%;
			}

			&--spacing {
				margin-bottom: $block-spacing * 2;
			}
		}

		@content;
	}
}
