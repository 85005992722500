$duration: 10s;

@keyframes ticker {
	0% {
		transform: translate3d(0, 0, 0);
		visibility: visible;
	}

	100% {
		transform: translate3d(-100%, 0, 0);
	}
}

.ticker-wrap {
	width: 100%;
	overflow: hidden;
	padding-left: 100%;
	box-sizing: content-box;
	height: 100%;

	.ticker {
		display: inline-block;
		white-space: nowrap;
		padding-right: 100%;
		box-sizing: content-box;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
		animation-name: ticker;
		animation-duration: $duration;
	}
}
