@include shoppingcart-table {
	color: $black;

	&__image-placeholder {
		&::after {
			padding-bottom: 150%;
		}

		&--popup {
			width: 60px;
		}
	}

	&__row {
		white-space: normal;
	}

	&__cell {
		padding-right: $block-spacing;

		&--product-image {
			width: auto;
		}

		&:last-child {
			padding-right: 0;
		}

		&:last-child,
		&:nth-last-child(2) {
			white-space: nowrap;
		}
	}

	&__price {
		color: $font-color;
	}

	&__price-advice {
		text-decoration: line-through;
		color: $font-color;
	}

	&__price-highlight {
		color: $highlight-color-2;
	}

	&__delete {
		&:hover {
			color: $highlight-color;
		}
	}
}
