@include language {
	color: $white;
	padding: 0;

	&--dropdown {
		.language__item {
			font-size: 14px;
			font-weight: 700;
			padding: 10px $block-spacing;
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			justify-content: space-between;
			gap: $block-spacing;

			&:hover {
				background-color: transparent;
			}
		}

		.language__items {
			background-color: $background-color;
			padding: 10px $block-spacing;
			width: 100%;
			gap: $block-spacing;
			flex-flow: row wrap;

			.language__item {
				color: $black;
				padding: 0;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		&:hover {
			.language__items {
				display: flex;
			}
		}
	}
}
