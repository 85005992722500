@mixin title {
	@at-root .title {
		color: $font-color;
		font-family: $secondary-font-family;
		font-size: 24px;
		font-weight: 700;

		&--1 {
			color: $font-color-2;
			text-transform: uppercase;
			font-size: 40px;
			font-weight: 400;
		}

		&--2 {
			color: $font-color-2;
			font-weight: 700;
			letter-spacing: 4.8px;
			font-size: 32px;
			text-transform: uppercase;
		}

		&--3 {
			font-size: 24px;
			text-transform: uppercase;
			font-weight: 700;
			line-height: normal;
			font-family: $primary-font-family;

			span {
				font-weight: 400;
				font-size: 24px;
			}
		}

		&--4 {
			font-size: 24px;
			font-weight: 700;
			font-family: $primary-font-family;
		}

		&--uppercase {
			text-transform: uppercase;
		}

		&--bold {
			font-weight: bold;
		}

		&--align-left {
			text-align: left;
		}

		&--align-center,
		&--center {
			text-align: center;
		}

		&--spacing {
			margin-top: $block-spacing * 4;
			margin-bottom: $block-spacing * 4;
		}

		&--spacing-top {
			margin-top: $block-spacing * 4;
		}

		&--spacing-bottom {
			margin-bottom: $block-spacing * 4;
		}

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			&--spacing {
				margin-top: $block-spacing * 2;
				margin-bottom: $block-spacing * 2;
			}

			&--spacing-top {
				margin-top: $block-spacing * 2;
			}

			&--spacing-bottom {
				margin-bottom: $block-spacing * 2;
			}

			&--align-center-tablet {
				text-align: center;
			}
		}

		@content;
	}
}
