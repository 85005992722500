@mixin logo {
	@at-root .logo {
		position: relative;
		display: block;
		height: 50px;
		width: 160px;
		margin: $block-spacing;

		&__image {
			@extend %position-center;

			display: block;
			max-height: 100%;
			max-width: 100%;

			&--light {
				display: none;
			}
		}

		@include responsive(('mobile')) {
			width: auto;
			flex: 1;
			max-width: 100px;
			margin-left: 0;
			margin-right: 0;
		}

		@content;
	}

	.header--transparent {
		.logo__image {
			display: none;

			&--light {
				display: block;
			}
		}

		&:hover {
			.logo__image {
				display: block;

				&--light {
					display: none;
				}
			}
		}
	}
}
