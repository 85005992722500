@include search {
	flex-grow: unset;

	&__icon {
		font-size: 16px;
		position: absolute;
		left: 16px;
		top: 50%;
		transform: translateY(-50%);
		pointer-events: none;
	}

	&__input {
		@extend %small;

		width: 333px;
		background-color: $white;
		border-radius: 6px;
		padding: $block-spacing;
		padding-left: 40px;

		&:focus {
			border-color: $border-color;
		}
	}

	&__overlay {
		position: fixed;
		bottom: 0;
		right: 0;
		width: 100vw;
		height: calc(100vh - 101px);
		background-color: rgba($black, 0.4);
		z-index: 9;
		display: none;

		&--active {
			display: block;
		}
	}

	&__dropdown {
		border-radius: 6px;
		padding: $block-spacing * 2;
		width: 428px;
		left: auto;
		right: 0;
		overflow-y: auto;
		max-height: calc(100vh - 100px);
		z-index: 11;

		&--active {
			display: flex;
			flex-flow: column nowrap;
			gap: $block-spacing;
		}
	}

	&__item {
		color: $black;
		font-size: 16px;
		font-weight: bold;
		display: block;
		padding: $block-spacing * 1.5 0;

		&--title {
			font-size: 18px;
		}

		&--sub {
			font-weight: normal;
			font-family: $primary-font-family;
		}
	}

	&__link {
		display: block;
		padding: $block-spacing * 2 0;
	}

	&__product {
		display: flex;
		align-items: center;
		width: 100%;
	}

	&__product-image {
		width: 50px;

		img {
			display: block;
			max-width: 100%;
		}
	}

	&__product-info {
		flex: 1;
		padding-left: $block-spacing * 2;
	}

	&__product-name {
		font-family: $primary-font-family;
		font-size: 14px;
		color: $black;
		font-weight: bold;
	}

	&__product-summary {
		font-size: 12px;
	}

	&__product-price {
		display: flex;
		align-items: center;
		gap: $block-spacing;
	}

	&__product-price-highlight {
		font-size: 14px;
		font-weight: 500;
		line-height: normal;
		color: $font-color;
		margin-right: $block-spacing;
	}

	&__product-price-advice {
		font-size: 12px;
		text-decoration: line-through;
		color: $font-color-2;
	}

	@include responsive(('desktop')) {
		&__input {
			width: 250px;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		position: fixed;
		top: 99px;
		left: 0;
		right: 0;
		width: 100%;
		background-color: $background-color;
		padding: $block-spacing;
		display: none;

		&__input {
			width: 100%;
			padding: $block-spacing;
			padding-left: 32px;
			border-radius: 0;
		}

		&__icon {
			left: 16px;
			top: 50%;
		}

		&__overlay {
			height: calc(100vh - 149px);
			display: none;

			&--active {
				display: none;
			}
		}

		&__dropdown {
			width: 100%;
			border-radius: 0;
			position: fixed;
			top: 149px;
			right: 0;
			bottom: 0;
			left: 0;
		}

		&--active {
			display: block;
		}
	}
}
