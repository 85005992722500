@include nav {
	justify-content: space-between;
	align-items: stretch;
	padding: 0 $block-spacing;

	&__link {
		position: relative;
		display: flex;
		align-items: center;
	}

	&__item {
		font-weight: 400;
		color: $black;
		text-transform: uppercase;
		font-size: 14px;

		&:hover {
			text-decoration: none;

			.nav__sub {
				display: flex;
			}
		}

		&--mobile {
			display: none;
		}

		&--active,
		&:hover {
			.nav__link {
				&::after {
					content: ' ';
					display: block;
					position: absolute;
					bottom: -1px;
					left: 0;
					width: 100%;
					height: 5px;
					background-color: $brand-color;
				}
			}
		}

		&--relative {
			position: relative;
		}
	}

	&__icon {
		font-size: 18px;
		margin: 0;
		cursor: pointer;
	}

	&__count {
		position: absolute;
		top: -9px;
		right: -16px;
		min-width: 18px;
		height: 18px;
		border-radius: 18px;
		text-align: center;
		line-height: 18px;
		color: $black;
		font-size: 10px;
		padding: 0 4px;
		font-weight: 700;
		background-color: $brand-color;
		font-family: $font-family-3;
	}

	&__container {
		flex: 1;

		&--left {
			align-items: stretch;

			.nav__item {
				display: flex;
				align-items: stretch;
				justify-content: center;
				padding: 0 $block-spacing * 3 0 0;
			}
		}

		&--right {
			.nav__item {
				padding: 0 0 0 $block-spacing * 3;

				&:last-child {
					margin-right: $block-spacing * 2;
				}

				&:hover {
					color: $black;
				}
			}
		}
	}

	&__sub-container {
		width: $grid-width;
		margin: 0 auto;
		max-width: 100%;
		display: flex;
		padding: $block-spacing * 2 0;
	}

	&__sub-items {
		width: 25%;
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		padding-top: $block-spacing * 6;
		padding-bottom: $block-spacing * 6;
		padding-left: $block-spacing;

		&--categories {
			border-right: 1px solid $border-color;
			padding-left: 0;
			padding-right: $block-spacing;
		}

		&:only-child {
			border: 0;
		}
	}

	&__sub-item {
		font-weight: 400;
		color: $black;
		text-transform: uppercase;
		font-size: 14px;
		display: block;
		width: 100%;
		padding: $block-spacing * 2 $block-spacing * 2 $block-spacing * 2 $block-spacing;
		position: relative;

		&--heading {
			font-weight: 700;
		}

		&:hover,
		&--active {
			color: $brand-color;
		}
	}

	&__sub-icon {
		position: absolute;
		top: 50%;
		right: $block-spacing;
		transform: translateY(-50%);
	}

	&__sub-inner {
		width: 75%;
		display: none;

		&--active {
			display: flex;
		}
	}

	&__sub {
		background-color: $white;
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		z-index: 9;
		display: none;
		align-items: center;
		justify-content: center;
		padding: 0 5%;
		border-bottom: 1px solid $border-color;

		&--small {
			width: 328px;
			right: auto;
			left: -$block-spacing * 4;
			border: 1px solid $border-color;

			.nav__sub-items {
				width: 100%;
				padding-left: $block-spacing * 3;
				padding-right: $block-spacing * 3;
				max-height: calc(100vh - 84px);
				overflow-y: auto;
			}
		}
	}

	&__mobile-dropdown {
		display: none;
		position: fixed;
		z-index: 9;
		background-color: $background-color;
		top: 96px;
		left: 0;
		right: 0;
		bottom: 0;

		&--active {
			display: block;
		}
	}

	&__mobile-container {
		height: 100%;
		display: flex;
		flex-direction: column;

		&--hidden {
			display: none;
		}
	}

	&__mobile-scroll {
		flex: 1;
		overflow-y: auto;
	}

	&__mobile-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: $block-spacing * 3 $block-spacing * 4;
		position: relative;

		&::after {
			content: ' ';
			display: block;
			position: absolute;
			width: calc(100% - #{$block-spacing * 8});
			height: 2px;
			background-color: $border-color;
			top: 100%;
			left: $block-spacing * 4;
		}
	}

	&__mobile-title {
		font-size: 18px;
		color: $black;
		font-weight: 700;
	}

	&__mobile-link {
		padding: $block-spacing * 3 $block-spacing * 4;
		flex: 1;
	}

	&__mobile-icon {
		padding: $block-spacing * 3 $block-spacing * 4;
		font-size: 18px;
	}

	&__mobile-item {
		font-size: 18px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: $black;

		&:first-child {
			margin-top: $block-spacing * 3;
		}

		&--sub {
			font-size: 16px;
			font-family: $primary-font-family;
			font-weight: normal;

			.nav__mobile-link,
			.nav__mobile-icon {
				padding: $block-spacing * 2 $block-spacing * 4;
			}

			&:first-child {
				margin-top: $block-spacing * 2;
			}
		}

		&--active {
			font-weight: 700;
		}
	}

	&__mobile-toggle,
	&__mobile-close,
	&__mobile-back {
		display: flex;
		align-items: center;
		font-size: 24px;
		color: $black;
		padding-left: $block-spacing;
		height: 100%;
		cursor: pointer;

		span {
			margin-left: $block-spacing;
			font-size: 14px;
			text-transform: uppercase;
		}
	}

	&__mobile {
		display: none;
		align-items: center;
		justify-content: flex-start;
		flex: 1;
	}

	@include responsive(('desktop')) {
		.nav__container--left .nav__item {
			padding-right: $block-spacing;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		&__mobile {
			display: flex;
		}

		&__item {
			&--desktop {
				display: none;
			}

			&--mobile {
				display: block;
			}
		}

		&__container--left {
			display: none;
		}

		&__container--right {
			.nav__item {
				padding-left: $block-spacing * 2;

				&:last-child {
					margin-right: $block-spacing;
				}
			}

			.nav__icon {
				font-size: 18px;
			}
		}
	}

	@include responsive(('mobile')) {
		flex-flow: row nowrap;

		&__mobile-toggle {
			padding-right: $block-spacing * 2;
		}

		&__container--right {
			min-width: 140px;
		}
	}
}

.header--transparent {
	.nav__item,
	.nav__container--left .nav__item {
		color: $white;
	}

	.nav__mobile-toggle {
		color: $white;
	}

	&:hover {
		.nav__item,
		.nav__container--left .nav__item {
			color: $black;
		}

		.nav__mobile-toggle {
			color: $black;
		}
	}
}
