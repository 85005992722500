.size-guide {
	&__scroll {
		overflow-y: auto;
		height: 100%;
	}

	&__dropdown {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 890px;
		max-height: 100vh;
		max-width: 100vw;
		background-color: $white;
		z-index: 9;
		border: 1px solid $border-color;
		border-radius: 8px;
		display: flex;
		flex-direction: column;
	}

	&__close {
		font-size: 18px;
		color: $black;
		top: $block-spacing * 2;
		right: $block-spacing * 2;
		cursor: pointer;
		position: absolute;
	}

	&__title {
		font-size: 18px;
		text-transform: uppercase;
		font-weight: 700;
		font-family: $primary-font-family;
		padding: $block-spacing * 2;
		color: $font-color;
	}

	&__heading {
		font-size: 16px;
		color: $black;
		font-weight: bold;
		padding: 0 $block-spacing * 2;

		i {
			margin-right: 12px;
		}
	}

	&__content {
		font-size: 16px;
		line-height: 1.5em;
		color: $font-color;
		flex: 1;
		padding: 0 $block-spacing * 2 $block-spacing * 2;

		img {
			max-width: 100%;
			height: auto;
		}
	}

	&__overlay {
		position: fixed;
		top: 0;
		right: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba($black, 0.4);
		z-index: 9;
	}

	&--hidden {
		.size-guide__overlay,
		.size-guide__dropdown {
			display: none;
		}
	}
}
