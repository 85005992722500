@include button {
	text-transform: uppercase;
	border: 0;
	font-weight: 600;
	min-width: unset;
	font-family: $primary-font-family;

	&:disabled {
		cursor: inherit;
		opacity: 0.4;
	}

	&--1,
	&--2,
	&--3 {
		background-color: $black;
		color: $white;
		min-height: 47px;
		line-height: 31px;
		text-decoration: none;
		font-size: 14px;
		font-weight: 700;

		&:hover:not(:disabled) {
			background-color: $brand-color;
			color: $black;
			text-decoration: none;
		}
	}

	&--4 {
		background-color: transparent;
		color: $black;
		min-height: 47px;
		line-height: 31px;
		text-decoration: none;
		font-size: 14px;
		font-weight: 400;
		text-transform: none;

		&:hover:not(:disabled) {
			text-decoration: underline;
		}
	}

	&--pay,
	&--print {
		height: 47px;
		line-height: 31px;
	}

	&--big {
		height: 56px;
		line-height: 40px;
	}

	&--grow {
		flex: 1 1 auto;
	}

	&--link {
		background-color: transparent;
		font-weight: 700;
		font-size: 14px;
		letter-spacing: 2.1px;
		color: $font-color-2;
		text-transform: uppercase;
		padding: $block-spacing 0;
		border-bottom: 1px solid $border-color-2;

		&:hover:not(:disabled) {
			background-color: transparent;
		}
	}

	&--link-dark {
		background-color: transparent;
		font-weight: 700;
		font-size: 14px;
		letter-spacing: 2.1px;
		color: $font-color;
		text-transform: uppercase;
		padding: $block-spacing 0;
		border-bottom: 1px solid $font-color;

		&:hover:not(:disabled) {
			background-color: transparent;
		}
	}

	&--link-light {
		background-color: transparent;
		font-weight: 700;
		font-size: 14px;
		color: $white;
		letter-spacing: 2.1px;
		text-transform: uppercase;
		padding: 4px 0;
		border-bottom: 1px solid $white;

		&:hover:not(:disabled) {
			background-color: transparent;
		}
	}

	&--position-right {
		right: $block-spacing;
	}

	&--spacing {
		margin-top: $block-spacing * 4;
		margin-bottom: $block-spacing * 4;
	}

	&--spacing-top {
		margin-top: $block-spacing * 4;
	}

	&--spacing-bottom {
		margin-bottom: $block-spacing * 4;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
		&--mobile-hidden {
			display: none;
		}

		&--full-width-mobile {
			width: 100%;
		}

		&--position-mobile {
			position: unset;
			right: auto;
			top: auto;
			bottom: auto;
			left: auto;
			transform: none;
			margin-left: calc(8.3333% + #{$block-spacing});
			margin-right: calc(8.3333% + #{$block-spacing});
			margin-top: $block-spacing * 3;
			width: calc(100% - #{$block-spacing * 2});
		}

		&--spacing {
			margin-top: $block-spacing * 2;
			margin-bottom: $block-spacing * 2;
		}

		&--spacing-top {
			margin-top: $block-spacing * 2;
		}

		&--spacing-bottom {
			margin-bottom: $block-spacing * 2;
		}
	}
}
