@include header {
	padding: 0;
	border: 0;
	position: relative;

	&__placeholder {
		height: 101px;
	}

	&__fixed {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		z-index: 9;
		background-color: $background-color;
		transition: background 0.2s ease;
	}

	&__top {
		background-color: $background-color-3;
		padding: $block-spacing / 2 0;
	}

	&__top,
	&__mid {
		@extend %flex-row;

		align-items: center;
		justify-content: center;
		width: 100%;
		padding: 0 5%;
	}

	&__mid {
		background-color: $white;
		border-bottom: 1px solid $border-color;
	}

	&__container {
		@extend %flex-row;

		align-items: center;
		justify-content: space-between;
		width: 100%;
		max-width: $grid-width;

		&--nowrap {
			flex-flow: row nowrap;
		}
	}

	&--scrolled {
		.header__fixed {
			top: -40px;
		}

		.header__placeholder {
			height: 66px;
		}
	}

	&--transparent {
		.header__placeholder {
			display: none;
		}

		.header__mid,
		.header__fixed {
			background-color: transparent;
			border-color: transparent;
		}

		&:hover {
			.header__mid {
				background-color: $white;
				border-bottom: 1px solid $border-color;
			}

			.header__fixed {
				background-color: $background-color;
			}
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		&__fixed {
			min-height: unset;
		}

		&__placeholder {
			height: 99px;
		}

		&__top {
			padding: 0;
		}

		&__mid {
			display: flex;
			align-items: stretch;
		}

		&__top,
		&__mid {
			padding: 0;
		}

		&__container {
			flex: 1;
			display: flex;
			align-items: stretch;
		}

		.language {
			display: none;
		}

		&--scrolled {
			.header__placeholder {
				height: 62px;
			}

			.header__fixed {
				top: -32px;
			}

			.nav__mobile-dropdown {
				top: 62px;
			}

			.search {
				top: 62px;
			}

			.search__dropdown {
				top: calc(62px + 73px);
			}
		}
	}
}
