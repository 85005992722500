@include product-info {
	width: 530px;
	max-width: 100%;
	background-color: $white;
	padding: 0 $block-spacing $vertical-spacing 8%;
	position: relative;
	display: flex;
	flex-flow: column wrap;
	gap: $block-spacing;

	&__container {
		@extend %flex-row;

		justify-content: space-between;
		align-items: center;
		gap: $block-spacing;

		&--atb {
			padding: $block-spacing 0;
			flex-wrap: nowrap;

			@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
				flex-flow: row wrap;

				.button {
					width: 100%;
					order: 3;
				}
			}
		}
	}

	&__tags {
		@extend %flex-row;

		gap: $block-spacing / 2;
		padding-bottom: $block-spacing;
	}

	&__tag {
		font-size: 14px;
		font-family: $font-family-3;
		letter-spacing: 2.1px;
		line-height: normal;
		padding: $block-spacing / 2;
		font-weight: 800;
		display: inline-block;
		position: unset;
		background-color: $black;
		color: $white;
		text-transform: uppercase;

		&--2 {
			background-color: $highlight-color-2;
		}

		&--eco-friendly {
			position: absolute;
			top: 0;
			left: 0;
			pointer-events: none;
			z-index: 8;
			height: 150px;
			width: 100%;
			background-color: transparent;
			background-image: url('#{$image-path}/eco_friendly.png');
			background-size: contain;
			background-position: top left;
			background-repeat: no-repeat;
		}
	}

	&__category {
		font-family: $primary-font-family;
		text-transform: uppercase;
		color: $font-color-2;
		font-size: 14px;
		font-weight: 300;
		letter-spacing: 2.1px;
		line-height: normal;
	}

	&__name {
		color: $font-color;
		font-family: $primary-font-family;
		font-weight: 700;
		line-height: normal;
		font-size: 24px;
		padding-bottom: $block-spacing;
	}

	&__option {
		font-size: 12px;
		font-family: $primary-font-family;
		font-weight: 400;
		line-height: normal;
		letter-spacing: 1.8px;
		text-transform: uppercase;
		color: $font-color-2;

		&--size-chart {
			width: auto;
			color: $font-color;
			text-transform: unset;
			letter-spacing: 2.1px;
			text-decoration: underline;
			text-align: left;

			i {
				font-size: 16px;
				margin-right: $block-spacing;
			}
		}

		&--set {
			text-align: left;
			padding: $block-spacing * 2 0;
			width: 100%;
		}
	}

	&__price {
		font-size: 24px;
		font-weight: 500;
		line-height: normal;
		gap: $block-spacing;
		flex-flow: row wrap;
		align-items: flex-end;
		margin-bottom: $block-spacing;
		color: $font-color-2;

		&__advice {
			text-decoration: line-through;
			color: $font-color-2;
		}

		&__highlight {
			color: $highlight-color-2;
			margin: 0;

			&--solo {
				font-size: inherit;
				line-height: inherit;
				color: $font-color-2;
			}
		}

		span {
			font-size: 12px;
			font-weight: 300;
			letter-spacing: 1.8px;
			line-height: normal;
			padding-bottom: 3px;
		}
	}

	&__summary {
		@extend %small;

		border-bottom: 1px solid $border-color;
		padding-bottom: $block-spacing * 2;
		margin-bottom: $block-spacing * 2;
	}

	&__description {
		@extend %paragraph;

		color: $black;
		margin-bottom: $block-spacing * 2;
	}

	@include responsive(('mobile', 'mobile-landscape')) {
		width: 100%;
		padding: $block-spacing * 3 8% 0;
	}
}
