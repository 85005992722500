.banners {
	display: flex;
	flex-flow: row wrap;
	align-items: stretch;
	width: 100%;
	padding: $block-spacing 0;
	gap: $block-spacing;

	&--sets {
		.media {
			max-width: calc(33.33% - #{$block-spacing / 1.5});
		}

		@include responsive(('tablet')) {
			.media {
				max-width: 50%;
			}
		}

		@include responsive(('mobile', 'mobile-landscape')) {
			.media {
				max-width: 100%;
			}
		}
	}

	&--home {
		.media {
			max-width: calc(33.33% - #{$block-spacing / 1.5});

			&:first-child,
			&:nth-child(2) {
				max-width: calc(50% - #{$block-spacing / 2});
			}
		}

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			.media {
				max-width: 100%;

				&:first-child,
				&:nth-child(2) {
					max-width: 100%;
				}
			}
		}
	}
}
