@include pagination {
	&__link {
		position: relative;

		&--disabled {
			cursor: default;
			opacity: 0.4;
		}
	}

	&__loader {
		display: none;
		position: absolute;
		left: -25px;

		&--active {
			display: initial;
		}
	}
}
