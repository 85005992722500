@mixin product-images {
	@at-root .product-images {
		width: 100%;

		&--hidden {
			display: none;
		}

		&__image {
			width: 100%;
			display: block;
			flex-shrink: 0;
			position: relative;
		}

		&__wrapper {
			position: relative;
			width: 100%;
			height: 100%;
			z-index: 1;
			display: flex;
			box-sizing: content-box;
		}

		&__carousel {
			padding: 0;
			margin-left: auto;
			margin-right: auto;
			position: relative;
			overflow: hidden;
			list-style: none;
			z-index: 1;
		}

		&__image img {
			max-width: 100%;
			width: 100%;
			height: auto;
			object-fit: cover;
			object-position: center;
		}

		&__bullets {
			@extend %flex-row;

			text-align: center;
			transition: 0.3s opacity;
			z-index: 10;
		}

		&__bullet {
			padding: $block-spacing $block-spacing $block-spacing 0;
		}

		.owl-dots {
			display: none;
		}

		@content;
	}
}
