@mixin number {
	@at-root .number {
		@extend %flex-row;

		align-items: center;
		flex-flow: row nowrap;
		flex-shrink: 0;

		&__button {
			font-size: 12px;
			font-weight: 400;
			color: $font-color;
			cursor: pointer;
			display: inline-block;
			position: relative;
			width: 47px;
			height: 47px;
			line-height: 47px;
			text-align: center;
			background: $white;
			border: 1px solid $black;

			i {
				line-height: inherit;
			}

			&:hover {
				background: $brand-color;
				color: $black;
			}

			&:first-child {
				border-right: 0;
			}

			&:last-child {
				border-left: 0;
			}
		}

		&__input {
			@extend %input;

			font-size: 14px;
			color: $font-color;
			border: 0;
			border: 1px solid $black;
			cursor: pointer;
			height: 47px;
			width: 47px;
			background-color: $white;
			text-align: center;

			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				appearance: none;
				margin: 0;
			}
		}

		&--disabled {
			pointer-events: none;
			opacity: 0.5;
		}

		@content;
	}
}
