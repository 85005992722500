@mixin carousel {
	@at-root .carousel {
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
		opacity: 0;
		transition: opacity 300ms ease-in-out;

		&--visable {
			overflow: visible;
		}

		&--initialized {
			opacity: 1;
		}

		&__slides {
			height: 100%;
			font-size: 0;
			display: flex;
			align-items: stretch;
			flex-flow: row nowrap;
		}

		&__slide {
			vertical-align: top;
			display: flex;
			flex: 0 0 auto;
			white-space: normal;
			padding: $block-spacing;

			&--no-spacing {
				padding: 0;
			}
		}

		&__prev {
			@extend %position-left;

			left: $block-spacing;
		}

		&__next {
			@extend %position-right;

			right: $block-spacing;
		}

		&__next,
		&__prev {
			outline: none;
			cursor: pointer;
			height: 56px;
			width: 56px;
			color: $white;
			font-size: 22px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid $black;
			background-color: $black;
			transition: background-color 0.3s ease-in-out 0s;

			&:hover {
				background-color: $background-color-2;
				border-color: $background-color-2;
			}
		}

		&__progress {
			@extend %flex-row;

			position: absolute;
			justify-content: center;
			align-items: center;
			padding: $block-spacing;

			&--hero {
				bottom: 40px !important;
			}
		}

		&__bullet {
			height: 24px;
			width: 24px;
			border-radius: 0;
			background-color: transparent;
			margin: $block-spacing;
			cursor: pointer;
			display: flex;
			align-items: center;

			&::after {
				content: ' ';
				width: 24px;
				height: 2px;
				background-color: rgba($white, 0.5);
			}

			&:hover,
			&--active {
				background-color: transparent;

				&::after {
					background-color: $white;
				}
			}
		}

		@content;
	}
}
