.social {
	&__container {
		@extend %flex-row;

		padding: $block-spacing * 5 0;
		align-items: center;
		justify-content: space-between;
	}

	&__title {
		color: $font-color;
		font-size: 32px;
		font-weight: 700;
		letter-spacing: 4.8px;
		text-transform: uppercase;
	}

	&__items {
		@extend %flex-row;

		align-items: center;
		justify-content: flex-end;
	}

	&__item {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 32px;
		width: 32px;
		border-radius: 32px;
		font-size: 18px;
		background-color: $black;
		color: $white;
		margin: $block-spacing;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		&__container {
			padding: 0 0 $block-spacing * 5;
			flex-flow: column wrap;
			gap: $block-spacing * 2;
		}
	}
}
