// path
$font-path: '#{$root}/fonts';
$image-path: '#{$root}/img';

// fonts
// $gazpacho2: gazpacho2;
$primary-font-family: 'Manrope', sans-serif;
// $secondary-font-family: $gazpacho2; //, serif;
$secondary-font-family: 'Playfair Display', serif;
$font-family-3: 'Mulish', sans-serif;

// colors
$black: #000;
$white: #fff;

// brand color
$brand-color: #7ddbd2;
$brand-color-2: $brand-color;

// font colors
$font-color: $black;
$font-color-2: #52565a;
$font-color-3: #989898;

// background colors
$background-color: #f5f5f5;
$background-color-2: #52565a;
$background-color-3: #2b2b2b;
$body-background-color: $white;

// border colors
$border-color: #f0f0f0;
$border-color-2: #52565a;

// placeholder colors
$placeholder-color: rgba($black, 0.6);

// overlay colors
$overlay-color: $black;

// error and messages colors
$warning-color: #f5a623;
$error-color: #d01d1b;
$success-color: #68b21e;

// highlight colors
$highlight-color: #ff6868;
$highlight-color-2: #e30612;

// hover colors
$hover-color: #ab5852;

// active colors
$active-color: #ab5852;

$whatsapp-color: #7ba889;

// grid
$grid-width: 1640px;
$block-spacing: 8px;
$grid-spacing: $block-spacing;
$grid-column-horizontal-spacing: $block-spacing;
$grid-column-vertical-spacing: $block-spacing;

$vertical-spacing: $block-spacing * 6;
$vertical-spacing-mobile: $block-spacing * 4;

$horizontal-spacing: 4%;
$horizontal-spacing-mobile: $block-spacing * 3;

$responsive-max-resolutions: (
	desktop-hd: 1640,
	desktop: 1440,
	tablet-landscape: 1140,
	tablet: 960,
	mobile-landscape: 768,
	mobile: 568,
);

$grid-responsive-modes: (
	desktop: '(max-width: #{(map-get($responsive-max-resolutions, desktop) - 1)}px)',
	tablet-landscape: '(max-width: #{(map-get($responsive-max-resolutions, tablet-landscape) - 1)}px)',
	tablet: '(max-width: #{(map-get($responsive-max-resolutions, tablet) - 1)}px)',
	mobile-landscape: '(max-width: #{(map-get($responsive-max-resolutions, mobile-landscape) - 1)}px)',
	mobile: '(max-width: #{(map-get($responsive-max-resolutions, mobile) - 1)}px)',
);

$responsive-modes: (
	desktop-hd: '(min-width: #{(map-get($responsive-max-resolutions, desktop) - 1)}px)',
	desktop: '(min-width: #{(map-get($responsive-max-resolutions, tablet-landscape) - 1)}px) and #{map-get($grid-responsive-modes, desktop)}',
	tablet-landscape: '(min-width: #{(map-get($responsive-max-resolutions, tablet) - 1)}px) and #{map-get($grid-responsive-modes, tablet-landscape)}',
	tablet: '(min-width: #{(map-get($responsive-max-resolutions, mobile-landscape) - 1)}px) and #{map-get($grid-responsive-modes, tablet)}',
	mobile-landscape: '(min-width: #{(map-get($responsive-max-resolutions, mobile) - 1)}px) and #{map-get($grid-responsive-modes, mobile-landscape)}',
	mobile: '(min-width: 0px) and #{map-get($grid-responsive-modes, mobile)}',
);

:root {
	--iti-path-flags-1x: url('#{$image-path}/flags.webp');
	--iti-path-flags-2x: url('#{$image-path}/flags@2x.webp');
}
