.set-info {
	width: 630px;
	max-width: 100%;
	background-color: $white;
	padding: 0 $block-spacing $vertical-spacing 8%;
	position: relative;
	display: flex;
	flex-flow: column nowrap;
	gap: $block-spacing;

	&__category {
		font-family: $primary-font-family;
		text-transform: uppercase;
		color: $font-color-2;
		font-size: 14px;
		font-weight: 300;
		letter-spacing: 2.1px;
		line-height: normal;
	}

	&__name {
		color: $font-color;
		font-family: $primary-font-family;
		font-weight: 700;
		line-height: normal;
		font-size: 24px;
		text-transform: uppercase;
	}

	&__container {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		gap: $block-spacing * 2;

		@include responsive(('mobile')) {
			flex-flow: column wrap;
		}
	}

	&__product {
		display: flex;
		flex-direction: column;
		margin-top: $block-spacing * 2;
	}

	&__product-info {
		@include responsive(('mobile')) {
			width: 100%;
		}
	}

	&__product-image {
		display: block;
		width: 170px;
		flex-shrink: 0;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		border: 1px solid $border-color;

		&::after {
			padding-bottom: 140%;
			content: ' ';
			display: block;
		}

		@include responsive(('mobile')) {
			width: 100%;
		}
	}

	&__product-category {
		font-size: 12px;
		color: $font-color-2;
		text-transform: uppercase;
		letter-spacing: 1.8px;
		line-height: normal;
		font-weight: 700;
		margin-bottom: $block-spacing / 2;
	}

	&__product-name {
		font-size: 12px;
		letter-spacing: 1.8px;
		color: $font-color;
		font-weight: 400;
		margin-bottom: $block-spacing;
	}

	&__price {
		font-size: 16px;
		font-weight: 700;
		line-height: normal;
		align-items: flex-end;
		margin-bottom: $block-spacing * 2;
		color: $font-color;
		gap: $block-spacing;
		display: flex;
		flex-flow: row wrap;

		&__advice {
			text-decoration: line-through;
			color: $font-color-3;
			display: inline;
		}

		&__highlight {
			color: $highlight-color-2;
			margin: 0;
			display: inline;
		}

		span {
			font-size: 12px;
			font-weight: 300;
			letter-spacing: 1.8px;
			line-height: normal;
		}
	}

	@include responsive(('mobile', 'mobile-landscape')) {
		width: 100%;
		padding: $block-spacing * 3 8% 0;
	}
}
