.sitemap {
	color: $font-color-2;
	margin-top: $block-spacing * 2.5;
	margin-bottom: $block-spacing * 2.5;
	width: 16.6666%;
	padding: $block-spacing;

	&--2 {
		width: 33.3333%;
		margin-left: auto;
	}

	&__title {
		font-family: $secondary-font-family;
		font-size: 16px;
		text-transform: uppercase;
		font-weight: 400;
		margin-bottom: $block-spacing * 2;
		display: block;
		color: $font-color;
	}

	&__item {
		font-size: 12px;
		font-weight: 400;
		letter-spacing: 1.8px;
		text-transform: uppercase;
		display: block;
		line-height: 24px;
		font-family: $font-family-3;
	}

	&__image {
		display: block;
		max-width: 100%;
		margin-top: $block-spacing * 2;
	}

	&--mobile {
		display: none;
	}

	a {
		&:hover {
			text-decoration: underline;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		width: 50%;

		&--2 {
			width: 100%;
		}
	}

	@include responsive(('mobile')) {
		width: 100%;
	}
}
