@import './external/autocomplete-address';

.address-autocomplete {
	&__input {
		width: 100%;
		height: 56px;
		border-width: 1px;
		border-radius: unset;
		outline: none;
		appearance: none;
		font-weight: 700;
		letter-spacing: 1.8px;
		text-transform: uppercase;
		font-family: $primary-font-family;
		color: $font-color;
		font-size: 12px;
		padding: 12px 16px 14px;

		&::placeholder {
			color: $font-color;
		}
	}

	input[class].postcodenl-autocomplete-address-input.postcodenl-autocomplete-loading {
		background-size: 1.8em;
	}
}
