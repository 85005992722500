@include shoppingcart-popup {
	&__bottom {
		display: flex;
		flex-flow: column wrap;
		gap: $block-spacing;
	}

	&__back-button {
		opacity: 0.5;
	}

	&__price-table {
		margin-bottom: 0;
	}
}
