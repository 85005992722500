.size-select {
	width: auto;
	position: relative;

	&__dropdown {
		appearance: none;
		width: 100%;
		height: auto;
		min-height: 47px;
		text-wrap: auto;
		border: 1px solid $black;
		color: $black;
		font-family: $primary-font-family;
		font-size: 14px;
		padding: 0 $block-spacing * 2;
		background-image: url('#{$image-path}/icons/angle-down.svg');
		background-repeat: no-repeat;
		background-position: center right $block-spacing * 2;
		background-size: 8px;
		border-radius: 0;
		font-weight: 700;
	}

	&__stock {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 0;
		height: 50px;
		user-select: none;
		pointer-events: none;
		right: 44px;
		color: $font-color;
		text-transform: uppercase;
		font-size: 12px;
		font-weight: bold;
	}

	&--full-width {
		width: 100%;
	}

	&--hidden {
		display: none;
	}

	@include responsive(('mobile')) {
		width: auto;

		&--full-width {
			width: 100%;
		}
	}
}
