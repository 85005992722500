.copyright {
	@extend %flex-row;

	align-items: center;
	justify-content: flex-start;
	width: 25%;
	padding: $block-spacing;

	&__item {
		@extend %paragraph;

		color: $black;
		font-weight: bold;
	}

	a {
		&:hover {
			text-decoration: underline;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		width: 100%;
		justify-content: center;
		order: 1;
		padding: $block-spacing * 4 0 $block-spacing 0;
	}
}
