@mixin wishlist {
	@at-root .wishlist {
		@extend %position-top-right;

		font-size: 20px;
		color: $highlight-color-2;
		padding: $block-spacing;

		&__icon,
		&__loader {
			visibility: visible;
			width: auto;

			&--hidden {
				visibility: hidden;
				width: 0;
			}
		}

		&--product {
			top: 0;
			right: 0;
			padding: $block-spacing * 2.5;
		}

		&--pdp {
			position: unset;
			top: auto;
			right: auto;
			border: 1px solid $black;
			height: 47px;
			width: 47px;
			font-size: 16px;
			color: $font-color;
			padding: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;

			&:hover {
				color: $highlight-color-2;
				border-color: $highlight-color-2;
			}
		}

		&--account {
			top: 10%;
		}

		@content;
	}
}
