@include color {
	z-index: 1;

	&__replacement {
		margin: $block-spacing / 2;
		height: 26px;
		width: 26px;
		border-radius: 26px;
		border: 1px solid $border-color;
		box-shadow: none;

		&::before {
			display: block;
			content: ' ';
			height: 34px;
			width: 34px;
			border-radius: 34px;
			background-color: $white;
			border: 1px solid transparent;
			z-index: -1;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		&:hover {
			border: 1px solid $border-color;
			box-shadow: none;
		}
	}

	&--filters .color__replacement {
		margin: $block-spacing / 2 $block-spacing $block-spacing / 2 2px;
	}

	&__input:checked + .color__replacement {
		border: 1px solid $border-color;
		box-shadow: none;

		&::before {
			border: 1px solid $black;
		}
	}
}
