.newsletter {
	display: flex;
	flex-flow: row wrap;
	gap: $block-spacing * 2;
	align-items: center;
	justify-content: space-between;
	padding: $block-spacing * 4 0;

	&__col {
		width: 50%;
		flex: 1;
	}

	@include responsive(('mobile')) {
		justify-content: center;
	}
}
