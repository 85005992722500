@include terms-and-conditions {
	align-items: center;
	justify-content: flex-start;

	&__item {
		font-size: 12px;
		text-transform: uppercase;
		letter-spacing: 1.8px;
		color: $black;
		padding: $block-spacing;
		position: relative;
		font-family: $font-family-3;

		&::after {
			content: ' ';
			display: block;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 0;
			height: 12px;
			width: 1px;
			background-color: $black;
		}

		&:last-child {
			&::after {
				display: none;
			}
		}
	}

	a {
		font-weight: 700;

		&:hover {
			text-decoration: underline;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		width: 100%;
		padding: $block-spacing * 2 $block-spacing;
		justify-content: center;
	}
}
