@include media {
	&__caption {
		width: 100%;
		gap: $block-spacing;
		padding: 0 10%;
		text-align: center;

		&--dark {
			color: $font-color;
		}

		&--hero {
			padding: 25% 10% 0;
		}

		&--bottom-left {
			transform: unset;
			top: unset;
			left: 0;
			bottom: 0;
			padding: $block-spacing * 5;
			justify-content: flex-start;
			align-items: flex-start;
			text-align: left;
			gap: 0;

			@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
				padding: $block-spacing * 4;
			}
		}
	}

	&__title {
		font-size: 48px;
		font-family: $secondary-font-family;
		font-weight: 400;
		text-align: inherit;

		&--large {
			font-size: 64px;
		}

		&--uppercase {
			text-transform: uppercase;
		}

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			font-size: 24px;

			&--large {
				font-size: 32px;
			}
		}
	}

	&__text {
		font-size: 16px;
		font-weight: 700;
		letter-spacing: 2.4px;
		font-family: $font-family-3;
		text-align: inherit;

		&--uppercase {
			text-transform: uppercase;
		}

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			font-size: 14px;
			letter-spacing: 2.1px;
		}
	}

	&--hero {
		background-position: center;
		height: 100vh;
		min-height: 700px;
	}

	&__video-container {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		iframe {
			width: 100%;
			height: 100%;
			min-height: 56.25vw;
			min-width: 177.77vh;
		}
	}

	&--hero-landscape {
		background-position: center right;
		height: 675px;
	}

	// &--video {
	// 	user-select: none;
	// 	pointer-events: none;
	// }

	&--120 {
		&::after {
			content: ' ';
			display: block;
			padding-bottom: 120%;
		}
	}

	&--140 {
		&::after {
			content: ' ';
			display: block;
			padding-bottom: 140%;
		}
	}

	&--145 {
		&::after {
			content: ' ';
			display: block;
			padding-bottom: 145%;
		}
	}

	&--shop {
		height: calc(100% - 67px);

		@include responsive(('tablet')) {
			height: calc(100% - 24px);
		}

		@include responsive(('mobile', 'mobile-landscape')) {
			height: auto;

			&::after {
				content: ' ';
				display: block;
				padding-bottom: 100%;
			}
		}
	}

	&--brand {
		position: absolute;
		top: calc(50% - #{$vertical-spacing / 2});
		right: $block-spacing;
		transform: translateY(-50%);
		width: calc(41.6666% - #{$block-spacing * 4});

		&::after {
			content: ' ';
			display: block;
			padding-bottom: 60%;
		}

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			top: auto;
			transform: none;
			bottom: auto;
			right: auto;
			position: static;
			order: 1;
			width: 100%;

			&::after {
				padding-bottom: 92%;
			}
		}
	}

	&__buttons {
		@extend %flex-row;

		gap: $block-spacing;

		&--center {
			justify-content: center;
		}
	}
}
